<template>
    <v-list v-if="orders && orders.length" two-line>
        <template v-for="(orders, createdAtFormatted) in ordersGroupedByDateCreate">
            <v-subheader :key="`ordersDate_${createdAtFormatted}`">
                {{ createdAtFormatted }}
            </v-subheader>

            <template v-for="Order in orders">
                <v-list-item
                    :key="`order_${Order.id}`"
                    :to="{name: 'purchaseHistoryOrder', params: {orderId: Order.id}}"
                >
                    <v-list-item-content>
                        <v-list-item-title>
                            Заказ №{{ Order.number }}
                            <v-chip
                                v-if="Order.status"
                                class="ma-2"
                                :color="Order.status.color"
                                text-color="white"
                                label
                            >
                                {{ Order.status.name }}
                            </v-chip>
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ Order.sum | currency }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </template>
    </v-list>
</template>

<script>
    import {
        VList,
        VListItem,
        VListItemContent,
        VListItemTitle,
        VListItemSubtitle,

        VSubheader,

        VChip,
    } from 'vuetify/lib'

    import {
        cloneDeep as _cloneDeep,
        groupBy as _groupBy,
    } from 'lodash'

    export default {
        name: 'OrderList',

        components: {
            VList,
            VListItem,
            VListItemContent,
            VListItemTitle,
            VListItemSubtitle,

            VSubheader,

            VChip,
        },

        props: {
            orders: { type: Array, default: null },
        },

        data() {
            return {}
        },

        computed: {
            ordersGroupedByDateCreate() {
                let orders = _cloneDeep(this.orders)

                return _groupBy(orders, 'createdAtFormatted')
            },
        },
        watch: {},
        beforeCreate() {

        },
        created() {

        },
        beforeMount() {

        },
        mounted() {

        },
        beforeUpdate() {

        },
        updated() {

        },
        activated() {

        },
        deactivated() {

        },
        beforeDestroy() {

        },
        destroyed() {

        },
        methods: {},
    }
</script>
